import React from "react";
import { Navbar } from "../components/navbar";
import { Hero } from "../components/hero";
import { Footer } from "../components/footer";
import { History } from "../components/history";
import { Plans } from "../components/plans";
import { Contacts } from "../components/contacts";

export const Home = () =>{
    return(
        <>
        <Navbar/>
        <Hero />
        <History />
        <Plans />
        <Contacts />
        <Footer />
        </>
    )
}