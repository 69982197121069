import React from "react";
import "../../styles/history.css"

export const History = () => {
    return (
        <>
            <div className="container-fluid" id="history">
                <div className="row d-flex justify-content-md-end py-lg-5 py-3">
                    <div className="col-lg-9 d-flex align-items-center py-lg-5 py-0">
                        <div className="container-fluid text-md-end text-center pt-3 me-lg-5">
                            <h1 className="display-4 fw-bold lh-1 text-black">La fuerza detrás de la</h1>
                            <h3 className="display-4 fw-bold lh-1">Innovación Notarial</h3>
                            <p className="lead text-black fs-3">Somos la perfecta alianza entre tecnología y jurisprudencia, dedicados a
                                transformar la práctica notarial. Combinamos una profunda comprensión del ámbito legal con soluciones
                                tecnológicas avanzadas para facilitar tu trabajo diario. Nuestro objetivo es brindarte herramientas que te
                                permitan trabajar con más agilidad y precisión, para que cada acto notarial refleje tu profesionalismo y
                                compromiso.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}