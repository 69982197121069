import React from "react";
import "../../styles/footer.css"

export const Footer = () => {
    return (
        <>
            <div className="container-fluid bg-dark">
                <footer className="py-3">
                    <p className="text-center text-white fs-5 mb-0">&copy; AbogaPro&reg;</p>
                    <p className="text-center text-white fs-5 mb-0">&copy; Powered by JCDesarrollo&reg;</p>
                </footer>
            </div>
        </>
    )
}
