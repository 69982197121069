import React, { useState, useEffect } from "react";
import "../../styles/contacts.css";
import facebook from "../../img/icon-facebook.svg";
import tiktok from "../../img/icon-tiktok.svg";
import twitterx from "../../img/icon-twitterx.svg";
import instagram from "../../img/icon-instagram.svg";
import email from "../../img/icon-email.svg";

export const Contacts = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Enlaces de redes sociales
  const socialLinks = [
    { src: facebook, label: "Facebook", url: "https://www.facebook.com/abogapro/" },
    { src: tiktok, label: "TikTok", url: "https://www.tiktok.com/@abogapro/" },
    { src: twitterx, label: "Twitter", url: "https://x.com/abogapro/" },
    { src: instagram, label: "Instagram", url: "https://www.instagram.com/" },
    { src: email, label: "Email", url: "mailto:soporte@abogapro.com" },
  ];

  return (
    <>
      <div className="container-fluid" id="contact">
        <div className="row bg-white">
          <div className="col-lg-6 d-flex-column justify-content-center">
            <div className="container-fluid text-center p-3 mt-3">
              <h1 className="display-4 fw-bold lh-1 text-black">Eficiencia a tu alcance</h1>
              <h3 className="display-4 fw-bold lh-1">Contáctemos</h3>
            </div>
            {/* Formulario */}
            <div className="row d-flex justify-content-center my-3">
              <div className="col-sm-10">
                <form>
                  <div className="card rounded-1 border border-0">
                    <div className="card-body p-3">
                      {/* Campos del formulario */}
                      <div className="form-group">
                        <div className="input-group mb-4">
                          <div className="input-group-prepend d-flex align-items-center me-2">
                            <div className="input-group-text">
                              <i className="fa fa-user text-black"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control fs-3 b-hover"
                            placeholder="Nombre Completo"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-4">
                          <div className="input-group-prepend d-flex align-items-center me-2">
                            <div className="input-group-text">
                              <i className="fa fa-envelope text-black"></i>
                            </div>
                          </div>
                          <input
                            type="email"
                            className="form-control fs-3 b-hover"
                            placeholder="Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-4">
                          <div className="input-group-prepend d-flex align-items-center me-2">
                            <div className="input-group-text">
                              <i className="fa fa-phone text-black"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control fs-3 b-hover"
                            placeholder="Teléfono"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-4">
                          <div className="input-group-prepend d-flex align-items-center me-2">
                            <div className="input-group-text">
                              <i className="fa fa-comment text-black"></i>
                            </div>
                          </div>
                          <textarea
                            className="form-control fs-3 b-hover"
                            placeholder="Mensaje"
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mb-4 mt-5 mb-lg-3">
                        <button
                          type="submit"
                          className="color-gradient btn btn-info btn-md fs-2 px-4 me-md-2 fw-bold text-white"
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Redes sociales */}
          <div className="col-lg-6 d-flex-column justify-content-center">
            {isMobile ? (
              <>
                <div className="container-fluid text-center px-3 pb-4">
                  <h1 className="display-4 fw-bold lh-1 text-black">Síguenos en nuestras</h1>
                  <h3 className="display-4 fw-bold lh-1">Redes Sociales</h3>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-wrap mb-4">
                  {socialLinks.map((social, index) => (
                    <a
                      key={index}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={social.src}
                        alt={`Icono de ${social.label}`}
                        className="icon mx-2 my-2"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </a>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="container-fluid text-center p-3 mt-3">
                  <h1 className="display-4 fw-bold lh-1 text-black">Síguenos en nuestras</h1>
                  <h3 className="display-4 fw-bold lh-1">Redes Sociales</h3>
                </div>
                <div className="list-group row d-flex align-items-center my-3">
                  {socialLinks.map((social, index) => (
                    <a
                      key={index}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="list-group-item list-group-item-action border border-0"
                    >
                      <div className="row d-flex align-items-center py-3 justify-content-center">
                        <div className="col-6 item text-end">
                          <img className="icon me-3" src={social.src} alt={social.label} />
                        </div>
                        <div className="col-6 item">
                          <h3 className="mb-0 text-black">{social.label}</h3>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
