import React from "react";
import hero from "../../img/img-hero - resized_660_500.png"
import "../../styles/hero.css"

export const Hero = () => {
    return (
        <>
            <div className=" container-fluid bg-black" id="hero">
                <div className="row">
                    <div className="col-lg-7 d-flex align-items-center">
                        <div className="container-fluid bg-black text-md-start text-center pt-3 ms-lg-5">
                            <h1 className="display-4 fw-bold lh-1 text-white">Sabemos que tu tiempo</h1>
                            <h3 className="display-4 fw-bold lh-1 ">es dinero</h3>
                            <p className="lead text-white fs-3">AbogaPro te permite realizar cálculos de actos notariales, asistencia de
                                impresión
                                en protocolo, gestión de formatos de cartulación y mucho más, de manera ágil y sencilla.</p>
                            <div className="d-md-flex justify-content-md-start mb-4 mb-lg-3">
                                <button type="button" className="color-gradient btn btn-info btn-md fs-2 px-4 me-md-2 fw-bold text-white">
                                    Solicitar Demo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 pe-0 d-flex justify-content-md-end">
                        <img className=" d-none d-lg-block" src={hero} alt="" style={{ width: "720px" }} />
                    </div>
                </div>
            </div>
        </>
    )
} 