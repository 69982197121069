import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./js/components/scrollToTop";
import injectContext from "./js/store/appContext";
import { Home } from "./js/views/home.jsx";
import { Login } from "./js/views/login.jsx";

const Layout = () => {
    const basename = process.env.BASENAME || "";

    return (
        <>
            <BrowserRouter basename={basename}>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        {/* <Route path="/updateContact/:theid" element={<UpdateContact />} /> */}
                        <Route path="*" element={<h1>Not found!</h1>} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </>
    )

}

export default injectContext(Layout);