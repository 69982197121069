import React from "react";
import { Navbar } from "../components/navbar";
import { Footer } from "../components/footer";
import { LoginContainer } from "../components/loginContainer";

export const Login = ()=>{
    return(
        <>
        <Navbar />
        <LoginContainer />
        <Footer />
        </>
    )
}