import React from "react";
import "../../styles/login.css"
import logo from "../../img/logo_AbogaPro_white_background.svg"

export const LoginContainer = () => {
    return (
        <>

            <div className="py-5" id="login">

                <div className="row justify-content-center">

                    <div className="col-lg-4 d-none d-sm-block" id="register">



                        <div className="text-md-center p-5 mt-5">
                            <h1 className="display-4 fw-bold lh-1 nav-primary-item my-5">Crear cuenta</h1>
                            <p className="lead text-white fs-3 mb-5">Hazlo fácil, hazlo con AbogaPro y disfuta del trabajo
                                eficiente.</p>
                            <div className="d-md-flex justify-content-md-center mb-4 mb-lg-3">
                                <button type="button"
                                    className="color-gradient btn btn-info btn-md fs-2 px-4 me-md-2 fw-bold text-white">
                                    Registrarse
                                </button>
                            </div>
                        </div>




                    </div>
                    <div className="col-lg-4 col-12 bg-white">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-11">
                                <form id="formLogin" action="/login" method="POST" className="my-5 border border-1 py-4 px-3 px-lg-4 shadow-lg">
                                    <div className=" d-flex justify-content-center">
                                        <img className="mb-4" src={logo} alt="" width="80"
                                            height="80" />

                                    </div>

                                    <div className="form mb-3 fs-5">
                                        <label for="user">Email</label>
                                        <input type="text" className="form-control fs-5" id="user" name="user"
                                            placeholder="Ingresar email" />

                                    </div>

                                    <div className="form mb-5 fs-5">
                                        <label for="pass">Contraseña</label>
                                        <input type="password" className="form-control fs-5" id="pass" name="pass"
                                            placeholder="Ingresar contraseña" />
                                    </div>
                                    {/* se debe eliminar el anchor una vez que  la funcionalida del boton este lista */}
                                    <button className="w-100 btn btn-lg btn-dark" type="submit"><a href="/login" className="text-decoration-none text-white">Ingresar</a></button>
                                    
                                </form>


                                <div className="text-center">
                                    <button className="w-50 btn btn-lg btn-dark mt-3 d-lg-none color-gradient" type="submit">
                                        Registrarse
                                    </button>
                                    <a href="/login" className="text-decoration-none">
                                        <p className="my-4 text-black fs-5">¿Olvidades la contraseña?</p>
                                    </a>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>

            </div>



        </>
    )
}