import React from 'react';
import { createRoot } from 'react-dom/client';
import Layout from './layout.js';
import './App.css';

// Selecciona el elemento por ID correctamente
const rootElement = document.getElementById('root');

// Verifica si el elemento existe para evitar errores
if (rootElement) {
  const root = createRoot(rootElement);

  // Renderiza tu aplicación React
  root.render(<Layout />);
} else {
  console.error("No se encontró el elemento con el ID 'root'. Asegúrate de que exista en tu HTML.");
}
