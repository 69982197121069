import React from "react";
import "../../styles/plans.css"
import dobletic from "../../img/doble-tic.png"

export const Plans = () => {
    return (
        <>
            <div className="container-fluid bg-black" id="plans">
                <div className="row d-flex justify-content-evenly">
                    <div className="container-fluid bg-black text-center p-3 mt-3">
                        <h1 className="display-4 fw-bold lh-1 text-white me-3">Planes adecuados a</h1>
                        <h3 className="display-4 fw-bold lh-1">tus necesidades</h3>
                        <p className="lead text-white fs-3">Tenemos opciones diseñadas para brindarte justo lo que necesitas, sin pagar de
                            más. Encuentra el plan perfecto que se ajuste a tu ritmo de trabajo y maximiza tu eficiencia notarial.</p>
                    </div>
                    <div className="col-sm-3 mt-4 mb-5">
                        <div className="card bg-dark text-white">
                            <h5 className="card-header primary-color-text text-end fs-2">Gratis</h5>
                            <div className="card-body">
                                <h5 className="card-title fs-1 mb-5">Demo</h5>
                                <p className="card-text fs-4">El plan demo te da acceso de prueba por 30 días a todas las funcionalidades.</p>
                                <ul className="list-group">
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Calculadora Nortarial</li>
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Impresión de Protocolo</li>
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Formatos de Cartulación</li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-center mb-4 mb-lg-3">
                                <button type="button"
                                    className="color-gradient btn btn-info btn-md fs-2 px-4 me-md-2 fw-bold text-white mt-3 mb-3">
                                    Solicitar Demo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 mt-4 mb-5">
                        <div className="card bg-dark text-white">
                            <h5 className="card-header primary-color-text text-end fs-2">₡3,500.00</h5>
                            <div className="card-body">
                                <h5 className="card-title fs-1 mb-5">Mensual</h5>
                                <p className="card-text fs-4">El plan mensual te da acceso completo a todas las funcionalidades.</p>
                                <ul className="list-group">
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Calculadora Nortarial</li>
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Impresión de Protocolo</li>
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Formatos de Cartulación</li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-center mb-4 mb-lg-3 ">
                                <button type="button"
                                    className="color-gradient btn btn-info btn-md fs-2 px-4 me-md-2 fw-bold text-white mt-3 mb-3">
                                    Solicitar Demo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 mt-4 mb-5">
                        <div className="card bg-dark text-white">
                            <h5 className="card-header primary-color-text text-end fs-2">₡35,700.00</h5>
                            <div className="card-body">
                                <h5 className="card-title fs-1 mb-5">Anual</h5>
                                <p className="card-text fs-4">El plan anual te da acceso a todas las funcionalidades mientras ahorras.</p>
                                <ul className="list-group">
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Calculadora Nortarial</li>
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Impresión de Protocolo</li>
                                    <li className="list-group-item fs-4 border border-0 bg-dark text-white d-flex align-items-center"><img
                                        className="me-2" src={dobletic} alt="" />Formatos de Cartulación</li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-center mb-4 mb-lg-3">
                                <button type="button"
                                    className="color-gradient btn btn-info btn-md fs-2 px-4 me-md-2 fw-bold text-white mt-3 mb-3">
                                    Solicitar Demo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}