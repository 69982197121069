import React from "react";
import logo from "../../img/logo_AbogaPro_white_background.svg";
import { useLocation } from "react-router-dom";
import "../../styles/navbar.css";

export const Navbar = () => {
    const location = useLocation();
    const isHome = location.pathname === "/";

    // Contenido de la barra de navegación condicional
    const navbarContent = isHome ? (
        <ul className="navbar-nav d-flex align-items-center">
            <li className="nav-item fs-4">
                <a className="nav-link nav-secondary-item mx-4" href="#hero">
                    Inicio
                </a>
            </li>
            <li className="nav-item fs-4">
                <a className="nav-link nav-secondary-item mx-4" href="#history">
                    Nosotros
                </a>
            </li>
            <li className="nav-item fs-4">
                <a className="nav-link nav-secondary-item mx-4" href="#plans">
                    Planes
                </a>
            </li>
            <li className="nav-item fs-4">
                <a className="nav-link nav-secondary-item mx-4" href="#contact">
                    Contáctenos
                </a>
            </li>
            <li className="nav-item fs-3">
                <a className="nav-link nav-primary-item mx-4" href="/login">
                    LogIn
                </a>
            </li>
        </ul>
    ) : (
        <ul className="navbar-nav d-flex align-items-center">
            <li className="nav-item fs-3">
                <a className="nav-link nav-secondary-item mx-4" href="/">
                    Inicio
                </a>
            </li>
        </ul>
    );

    return (
        <nav className="navbar navbar-expand-lg sticky-top bg-dark">
            <div className="container-fluid">
                <div className="navbar-brand d-flex align-items-center">
                    <img src={logo} alt="Logo" style={{ width: "50px", height: "50px" }} />
                    <h1 className="ms-2 fs-1 fw-bold text-white mb-0">AbogaPro</h1>
                </div>
                <button
                    className="navbar-toggler text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    {isHome ? (
                        <i className="fa-solid fa-bars"></i>
                    ) : (
                        <a className="text-white" href="/">
                            <i className="fa-solid fa-home"></i>
                        </a>
                    )}
                </button>
                <div className="collapse navbar-collapse d-md-flex justify-content-md-end" id="navbarNav">
                    {navbarContent}
                </div>
            </div>
        </nav>
    );
};
